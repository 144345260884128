<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
      <ProfileEditFormPanel
        :form="form"
        :gender="gender"
        :consentShow="consentShow"
        :customField="customField"
        :dynamicField="dynamicField"
        :questionList="questionList"
        :branchList="branchList"
        :v="$v"
        :isEdit="true"
      />
    </div>
  </div>
</template>

<script>
import ProfileEditFormPanel from "../../../components/profile/edit/ProfileEditFormPanel";
import OtherLoading from "@/components/other/OtherLoading";
import {
  required,
  email,
  minLength,
  maxLength,
  numeric,
  requiredIf
} from "vuelidate/lib/validators";
import { fieldInForm2, profile_fields2 } from "@/assets/json/fieldProfile.json";

export default {
  components: {
    ProfileEditFormPanel,
    OtherLoading
  },
  validations() {
    for (const item of this.customField) {
      const key = this.checkIsProfile(item.field_profile_type_id);

      if (key) {
        if (item.required) {
          if (item.field_profile_type_id == 11) {
            profile_fields2[key] = { required, email };
          } else if (item.field_profile_type_id == 10)
            profile_fields2[key] = {
              required,
              maxLength: maxLength(10),
              minLength: minLength(10),
              numeric
            };
          else if (item.field_profile_type_id == 9) {
            profile_fields2["birthday_day"] = {
              required
            };
            profile_fields2["birthday_month"] = {
              required
            };
            profile_fields2["birthday_year"] = {
              required
            };
          } else if (item.field_profile_type_id == 1)
            profile_fields2[key] = {
              required,
              // Script_checkID,
              maxLength: maxLength(13),
              minLength: minLength(13),
              numeric
            };
          else profile_fields2[key] = { required };
        } else {
          if (item.field_profile_type_id == 11) {
            profile_fields2[key] = { email };
          } else profile_fields2[key] = {};
        }
      }
    }
    return {
      form: profile_fields2,
      dynamicField: {
        $each: {
          user_answer: {
            required: requiredIf(el => el.required)
          }
        }
      }
    };
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "ข้อมูลส่วนตัว",
          to: "/profile"
        }
      ],
      form: {
        picture: null,
        profile: null,
        total_point: null,
        is_consent: 0,
        ...profile_fields2
      },
      gender: [],
      isLoading: true,
      consentShow: false,
      customField: [],
      dynamicField: [],
      questionList: [],
      branchList: []
    };
  },
  async created() {
    this.getDataProfile();
    this.getGender();
    // this.getBranch();
  },
  methods: {
    checkIsProfile(val) {
      const findField = fieldInForm2.find(el => el.id == val);

      return findField ? findField.key : false;
    },
    async getDataProfile() {
      this.isLoading = true;
      var object = await this.$store.dispatch("getUserApi");

      Object.keys(object).map(
        el => (object[el] = this.checkField(object[el]) ? "" : object[el])
      );

      this.form = { ...object };

      this.form.birthday_year =
        this.form.have_year_birth === false ? "" : this.form.birthday_year;

      this.consentShow = this.form.is_consent === 1 ? true : false;
      await this.getCustomField();
      this.isLoading = false;
    },
    async getCustomField() {
      this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/user/registerform/1`)
        .then(async data => {
          this.isLoading = false;
          if (data.result === 1) {
            this.customField = data.detail.result.fields;
            for (const field of data.detail.result.custom_field) {
              let form = this.form.custom_field.find(el => el.id == field.id);

              if (form) {
                let value = form.user_answer.map(el => el.answer);

                if (field.field_type_id == 2) {
                  field.user_answer = value;
                } else {
                  field.user_answer = value.toString();
                }
              }
            }
            this.dynamicField = data.detail.result.custom_field.filter(
              el => el.show_on_edit_profile == 1
            );
          }
        });
    },
    checkField(val) {
      return val == "N/A" || val == null || val == undefined;
    },
    async getGender() {
      this.$axios.get(`${process.env.VUE_APP_API}/api/v1/gender`).then(data => {
        if (data.result == 1) {
          let genderList = [];
          let genderAll = data.detail;
          for (let i in genderAll) {
            if (genderAll.length > 0) {
              genderList.push({
                value: genderAll[i].id,
                text: genderAll[i].name
              });
              this.gender = genderList;
            }
          }
        }
      });
    },
    async getBranch() {
      this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Redeem/get_branch_list`)
        .then(
          async data => {
            // this.isLoading = false;
            if (data.result == 1) {
              this.branchList = data.detail;
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    }
  }
};
</script>

<style lang="scss" scoped>
.content-page {
  padding-bottom: unset;
}
</style>
