<template>
  <div>
    <div class="text-center">
      <div class="font-weight-bold f-24 mt-3">EDIT PROFILE</div>
    </div>

    <div class="px-3">
      <b-row class="row-gap align-items-center" no-gutters>
        <template v-for="(profile, index) in customField">
          <template v-if="profile.is_show == 1">
            <div :key="index" class="col-12" v-if="profile.field_type_id == 4">
              <InputSelect
                :title="profile.name"
                :text="profile.name"
                :name="profile.profile_name"
                :placeholder="profile.profile_name"
                valueField="name"
                textField="name"
                v-bind:options="
                  profile.field_choices.filter(el => el.is_display == 1)
                "
                v-model="form[checkIsProfile(profile.field_profile_type_id)]"
                :value="form[checkIsProfile(profile.field_profile_type_id)]"
                :isRequired="profile.required"
                class="f-regular"
                :isValidate="
                  v.form[checkIsProfile(profile.field_profile_type_id)].$error
                "
                :v="v.form[checkIsProfile(profile.field_profile_type_id)]"
                :isDisplay="isEdit"
              />
            </div>
            <div
              :key="index"
              class="col-12"
              v-else-if="profile.field_type_id == 7"
            >
              <b-row no-gutters>
                <b-col cols="12"
                  ><label for="" class="custom-label"
                    >{{ profile.name || "วันเดือนปีเกิด (ปี ค.ศ.)" }}
                    <span v-if="profile.required">*</span></label
                  ></b-col
                >

                <b-col class="pr-3">
                  <b-form-select
                    :class="[
                      'btn-select',
                      { borderError: v.form.birthday_day.$error }
                    ]"
                    v-model="form.birthday_day"
                    @change="handleDayChange"
                    :options="day"
                  >
                    <template #first>
                      <b-form-select-option value="" disabled selected>
                        <span>
                          <slot name="options-name">Day</slot>
                        </span>
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
                <b-col class="pr-3">
                  <b-form-select
                    :class="[
                      'btn-select',
                      { borderError: v.form.birthday_month.$error }
                    ]"
                    v-model="form.birthday_month"
                    :options="month"
                    @change="handleMonth"
                  >
                    <template #first>
                      <b-form-select-option value="" disabled selected>
                        <span>
                          <slot name="options-name">Month</slot>
                        </span>
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>

                <b-col>
                  <InputText
                    v-model="form.birthday_year"
                    textFloat=""
                    class="birth-date-input"
                    placeholder="Year"
                    type="number"
                    :isValidate="v.form.birthday_year.$error"
                    name="birthday_year"
                    @onDataChange="handleBirthYear"
                    @onkeyup="handleBirthYear"
                    @onPaste="handleBirthYear"
                    oninput="if( this.value.length > 4 )  this.value = this.value.slice(0,4)"
                  />
                </b-col>
                <b-col cols="12">
                  <div class="my-1"></div>
                  <small class="pb-2 pt-4 text-gray f-11"
                    >For example: 23/07/1995</small
                  >
                  <div
                    v-if="
                      v.form.birthday_year.$error ||
                      v.form.birthday_month.$error ||
                      v.form.birthday_day.$error
                    "
                  >
                    <span class="text-error f-11">
                      Please fill the correct date of birth</span
                    >
                  </div>
                </b-col>
              </b-row>
            </div>
            <div
              :key="index"
              :class="[
                profile.field_profile_type_id == 3 ||
                profile.field_profile_type_id == 5
                  ? 'col-6'
                  : 'col-12',
                profile.field_profile_type_id == 3 ? 'pr-3' : ''
              ]"
              class="position-relative"
              v-else-if="profile.field_type_id == 1"
            >
              <InputText
                v-if="!isLoading"
                :textFloat="profile.name"
                :placeholder="profile.name"
                :type="checkFieldConditon(profile)"
                :name="profile.name"
                size="lg"
                v-model.trim="
                  form[checkIsProfile(profile.field_profile_type_id)]
                "
                :maxlength="checkMaxMin(profile.field_conditions, 2)"
                :min="checkMaxMin(profile.field_conditions, 3)"
                :max="checkMaxMin(profile.field_conditions, 4)"
                :isRequired="profile.required"
                :isValidate="
                  v.form[checkIsProfile(profile.field_profile_type_id)].$error
                "
                :readonly="profile.field_profile_type_id == 10"
                :v="v.form[checkIsProfile(profile.field_profile_type_id)]"
                @onDataChange="isMaxMin"
                @onkeypress="isNumber"
              />
              <template v-if="profile.field_profile_type_id == 10">
                <div class="edit-telephone" @click="$router.push('/telephone')">
                  <font-awesome-icon icon="chevron-right"></font-awesome-icon>
                </div>
              </template>
            </div>
          </template>
        </template>
      </b-row>
      <b-row
        v-for="(fields, index) in dynamicField"
        :key="'dynamic-' + index"
        class="mt-3"
      >
        <b-col class="mb-3">
          <div v-if="fields.field_type_id == 1">
            <InputText
              v-if="!isLoading"
              :textFloat="fields.name"
              :placeholder="fields.name"
              :type="checkFieldConditon(fields)"
              name="firstname"
              size="lg"
              v-model.trim="fields.user_answer"
              :maxlength="checkMaxMin(fields.field_conditions, 2)"
              :min="checkMaxMin(fields.field_conditions, 3)"
              :max="checkMaxMin(fields.field_conditions, 4)"
              :isRequired="fields.required"
              :isDisplay="fields.field_profile_type_id == 10"
              @onDataChange="isMaxMin"
              :v="v.dynamicField.$each[index].user_answer"
              :validateText="fields.validate_text"
              :isValidate="v.dynamicField.$each[index].$error"
              @onkeypress="isNumber"
            />
          </div>

          <div v-else-if="fields.field_type_id == 2">
            <div class="input-custom">
              <label>
                {{ fields.name }}

                <span v-if="fields.required" class="text-danger">*</span>
              </label>

              <b-form-checkbox-group
                :id="`${fields.id}`"
                :options="fields.field_choices"
                value-field="name"
                v-model="fields.user_answer"
                text-field="name"
                stacked
              ></b-form-checkbox-group>
            </div>
            <div v-if="v.dynamicField.$each[index].$error" class="mt-2">
              <small class="text-error">{{ fields.validate_text }}</small>
            </div>
          </div>
          <div v-else-if="fields.field_type_id == 3">
            <div class="input-custom">
              <label>
                {{ fields.name }}

                <span v-if="fields.required" class="text-danger">*</span>
              </label>
              <b-form-radio-group
                :id="`${fields.id}`"
                :options="fields.field_choices"
                value-field="name"
                text-field="name"
                v-model="fields.user_answer"
                :value="fields.user_answer"
                stacked
                :checked="fields.user_answer"
              ></b-form-radio-group>
            </div>
            <div v-if="v.dynamicField.$each[index].$error" class="mt-2">
              <small class="text-error">{{ fields.validate_text }}</small>
            </div>
          </div>
          <div v-else-if="fields.field_type_id == 4">
            <InputSelect
              :title="fields.name"
              :text="fields.name"
              :name="fields.name"
              :placeholder="fields.name"
              valueField="name"
              textField="name"
              v-bind:options="fields.field_choices"
              v-model="fields.user_answer"
              :value="fields.user_answer"
              :isRequired="fields.required"
              class="f-regular"
              :v="v.dynamicField.$each[index].user_answer"
              :validateText="fields.validate_text"
              :isValidate="v.dynamicField.$each[index].$error"
            />
          </div>
          <div v-else-if="fields.field_type_id == 5">
            <div class="input-custom">
              <label>
                {{ fields.name }}

                <span v-if="fields.required" class="text-danger">*</span>
              </label>
              <datetime
                type="datetime"
                :placeholder="fields.name"
                :class="['date-picker-input date-filter']"
                :format="'dd MMM yyyy HH:mm'"
                value-zone="Asia/Bangkok"
                v-model="fields.user_answer"
              ></datetime>
              <!--  -->
              <div v-if="v.dynamicField.$each[index].$error" class="mt-2">
                <small class="text-error">{{ fields.validate_text }}</small>
              </div>
            </div>
          </div>
          <div v-else-if="fields.field_type_id == 6" class="input-custom">
            <InputSelect
              :title="fields.name"
              :text="fields.name"
              :name="fields.name"
              :placeholder="fields.name"
              valueField="name"
              textField="name"
              v-bind:options="branchList"
              v-model="fields.user_answer"
              :value="fields.user_answer"
              :isRequired="fields.required"
              class="f-regular"
              :v="v.dynamicField.$each[index].user_answer"
              :validateText="fields.validate_text"
              :isValidate="v.dynamicField.$each[index].$error"
            />
          </div>
          <!-- Date -->
          <div v-else-if="fields.field_type_id == 7">
            <div class="input-custom">
              <label>
                {{ fields.name }}

                <span v-if="fields.required" class="text-danger">*</span>
              </label>
              <datetime
                type="date"
                :placeholder="fields.name"
                :class="['date-picker-input date-filter']"
                :format="'dd MMM yyyy'"
                value-zone="Asia/Bangkok"
                v-model="fields.user_answer"
              ></datetime>
              <!--  -->
              <div v-if="v.dynamicField.$each[index].$error" class="mt-2">
                <small class="text-error">{{ fields.validate_text }}</small>
              </div>
            </div>
          </div>
          <!-- Image -->
          <div v-else-if="fields.field_type_id == 8" class="input-custom">
            <label>
              {{ fields.name }}

              <span v-if="fields.required" class="text-danger">*</span>
            </label>
            <CustomInputUploadImage
              :id="fields.id"
              :label="fields.name"
              :img="fields.user_answer"
              v-model="fields.user_answer"
              :v="v.dynamicField.$each[index].user_answer"
              :validateText="fields.validate_text"
              :isValidate="v.dynamicField.$each[index].$error"
            />
            <div v-if="v.dynamicField.$each[index].$error" class="mt-2">
              <small class="text-error">{{ fields.validate_text }}</small>
            </div>
          </div>
          <div v-else-if="fields.field_type_id == 10">
            <h2>{{ fields.name }}</h2>
          </div>
          <div v-else-if="fields.field_type_id == 11">
            <h5>{{ fields.name }}</h5>
          </div>
          <div v-else-if="fields.field_type_id == 12">
            <InputTextArea
              :id="fields.id"
              :textFloat="fields.name"
              type="text"
              class="mt-3 custom-input"
              :placeholder="fields.name"
              rows="3"
              v-model="fields.user_answer"
              :v="v.dynamicField.$each[index].user_answer"
              :isRequired="fields.required"
              :validateText="fields.validate_text"
              :isValidate="v.dynamicField.$each[index].$error"
            />
            <!-- <div v-if="v.dynamicField.$each[index].$error" class="mt-2">
                <small class="text-error">{{ fields.validate_text }}</small>
              </div> -->
          </div>
        </b-col>
      </b-row>

      <b-row class="px-3 f-14 mt-3" style="margin-bottom: 80px">
        <b-col cols="12" class="p-0" v-if="!isEdit">
          <b-form-checkbox
            v-model="form.is_consent"
            :value="1"
            :unchecked-value="0"
            name="checkbox-1"
            ref="input"
            class="consent-text"
          >
            I'd like to receive personalized offer and be the first to know
            about the latest Alo Thailand updates via email.
          </b-form-checkbox>
        </b-col>

        <b-col cols="12" class="p-0">
          <b-form-checkbox
            v-model="form.is_privacy_policy"
            :value="1"
            :unchecked-value="0"
            name="checkbox-2"
            class="consent-text"
          >
            I certify that i have read, understand and agree to Alo Thailand
            <span class="text-underline" @click.prevent="modalTermsOpen"
              >Privacy Notice</span
            >
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
    <!-- <div class="div-space"></div> -->
    <div class="register-bottom">
      <b-button
        class="w-100"
        :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
        :disabled="form.is_privacy_policy === 0 ? true : false"
        @click.prevent="validateEmail"
        >SUBMIT</b-button
      >
    </div>
    <ModalPolicy ref="modalPolicy" />
    <ModalTermAndConditios ref="modalTerms" />
    <ModalError ref="modalError" :text="successMessage" />
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
import InputSelect from "@/components/input/InputSelect";
import CustomFieldPanel from "@/components/custom-fields/CustomFieldPanel";
import moment from "moment";
import CustomInputUploadImage from "@/components/input/CustomInputUploadImage";
import ModalPolicy from "@/components/alert-modal/ModalPolicy";
import ModalTermAndConditios from "@/components/alert-modal/ModalTermAndConditios";
import { fieldInForm2, fieldInForm } from "@/assets/json/fieldProfile.json";
import InputTextArea from "@/components/input/InputTextArea";
import ModalError from "@/components/alert-modal/ModalError";
export default {
  props: {
    form: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    errorBirthDate: {
      required: false,
      type: String
    },
    isLoading: {
      required: false,
      type: Boolean
    },
    title: {
      required: false,
      type: String
    },

    disableBtn: {
      required: false,
      type: Boolean
    },
    customField: {
      required: true,
      type: Array
    },
    dynamicField: {
      required: true,
      type: Array
    },
    questionList: {
      required: true,
      type: Array
    },

    branchList: {
      required: false
    },
    isEdit: {
      required: false
    }
  },
  components: {
    InputText,
    InputSelect,
    CustomFieldPanel,
    ModalPolicy,
    ModalTermAndConditios,
    CustomInputUploadImage,
    InputTextArea,
    ModalError
  },
  data() {
    return {
      acceptTerm: false,
      required: false,
      birthDay18: true,

      day: [
        { value: "01", text: "01", disabled: false },
        { value: "02", text: "02", disabled: false },
        { value: "03", text: "03", disabled: false },
        { value: "04", text: "04", disabled: false },
        { value: "05", text: "05", disabled: false },
        { value: "06", text: "06", disabled: false },
        { value: "07", text: "07", disabled: false },
        { value: "08", text: "08", disabled: false },
        { value: "09", text: "09", disabled: false },
        { value: "10", text: "10", disabled: false },
        { value: "11", text: "11", disabled: false },
        { value: "12", text: "12", disabled: false },
        { value: "13", text: "13", disabled: false },
        { value: "14", text: "14", disabled: false },
        { value: "15", text: "15", disabled: false },
        { value: "16", text: "16", disabled: false },
        { value: "17", text: "17", disabled: false },
        { value: "18", text: "18", disabled: false },
        { value: "19", text: "19", disabled: false },
        { value: "20", text: "20", disabled: false },
        { value: "21", text: "21", disabled: false },
        { value: "22", text: "22", disabled: false },
        { value: "23", text: "23", disabled: false },
        { value: "24", text: "24", disabled: false },
        { value: "25", text: "25", disabled: false },
        { value: "26", text: "26", disabled: false },
        { value: "27", text: "27", disabled: false },
        { value: "28", text: "28", disabled: false },
        { value: "29", text: "29", disabled: false },
        { value: "30", text: "30", disabled: false },
        { value: "31", text: "31", disabled: false }
      ],
      month: [
        { value: "01", text: "มกราคม", disabled: false },
        { value: "02", text: "กุมภาพันธ์", disabled: false },
        { value: "03", text: "มีนาคม", disabled: false },
        { value: "04", text: "เมษายน", disabled: false },
        { value: "05", text: "พฤษภาคม", disabled: false },
        { value: "06", text: "มิถุนายน", disabled: false },
        { value: "07", text: "กรกฎาคม", disabled: false },
        { value: "08", text: "สิงหาคม", disabled: false },
        { value: "09", text: "กันยายน", disabled: false },
        { value: "10", text: "ตุลาคม", disabled: false },
        { value: "11", text: "พฤศจิกายน", disabled: false },
        { value: "12", text: "ธันวาคม", disabled: false }
      ],
      successMessage: ""
    };
  },
  created() {
    let index = 0;
    for (const month of this.month) {
      month.text = moment().month(index).format("MMMM");
      index++;
    }
    this.emailFirst = this.form.email;
    this.handleMonth(this.form.birthday_month);
    this.handleDayChange(this.form.birthday_day);
  },
  methods: {
    handleMonth(vals) {
      let length = this.day.length - 1;
      let disabledMonth = ["04", "06", "09", "11"];
      if (vals == "02") {
        this.day[length].disabled = true;
        this.day[length - 1].disabled = true;
      } else if (disabledMonth.includes(vals)) {
        this.day[length].disabled = true;
        this.day[length - 1].disabled = false;
      } else {
        this.day[length].disabled = false;
        this.day[length - 1].disabled = false;
      }
    },
    handleDayChange(vals) {
      let disabledMonth = ["04", "06", "09", "11"];
      if (vals == 30 || vals == 31) {
        if (vals == 31) {
          this.$nextTick(() => {
            this.month.map(el => {
              if (disabledMonth.includes(el.value)) {
                el.disabled = true;
              }
              return { ...el };
            });
            this.month = this.month;
          });
        } else if (vals == 30) {
          this.month.map(el => {
            el.disabled = false;
            return { ...el };
          });
        }
        this.$nextTick(() => {
          this.month[1].disabled = true;
        });
      } else {
        this.month.map(el => {
          el.disabled = false;
          return { ...el };
        });
      }
    },
    handleBirthYear(val) {
      let year = moment().year();
      if (val > year) {
        this.form.birthday_year = year;
      }
    },
    handleLink() {
      if (this.$store.state.theme.is_right_request_url == "1")
        return window.open(this.$store.state.theme.right_request_url, "_blank");

      this.$router.push("/setting");
    },
    checkFieldConditon(obj) {
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },
    checkIsProfile(val) {
      const findField = fieldInForm2.find(el => el.id == val);

      return findField ? findField.key : false;
    },
    checkMaxMin(item, key, field_id) {
      if (field_id == 9) {
        const condition = item.find(el => el.field_condition_type_id == 7);

        if (condition)
          if (key == 3) {
            return condition.value
              ? moment().subtract(condition.value, "year").format("YYYY-01-01")
              : false;
          } else if (key == 4) return moment().format("YYYY-MM-DD");
      }
      const conditon = item.find(el => el.field_condition_type_id == key);
      if (conditon) return conditon.value ? conditon.value : false;
      return false;
    },
    isMaxMin: function (value, e) {
      try {
        const val = e.target.value;

        const max = Number(e.target.max);
        const min = Number(e.target.min);
        if (val && e.target.type == "number" && min && val < min) {
          return (e.target.value = min);
        }
        if (val && e.target.type == "number" && max && val > max) {
          return (e.target.value = max);
        }
      } catch (error) {
        console.log(error);
      }
    },
    isNumber: function (e) {
      const maxlen = e.target.maxLength;
      const val = e.target.value;

      if (
        (e.key == "e" || e.charCode == 45 || e.charCode == 46) &&
        e.target.type == "number"
      ) {
        return e.preventDefault();
      }
      if (maxlen == -1) {
        return val;
      }

      if (val.length > maxlen - 1) {
        return e.preventDefault();
      }
    },
    handleChangeGender(val) {
      this.form.gender = val;
    },
    dateFormat() {
      let today = new Date();
      return moment(today).format();
    },
    nextPage() {
      this.v.form.$touch();
      console.log(this.v.form);
      if (this.v.form.$error) {
        return;
      }
      this.tabIndex = 1;
    },
    async validateEmail() {
      if (this.hasSubmit) {
        return this.$emit("onSubmit");
      }

      if (this.form.email !== this.emailFirst) {
        this.v.form.$touch();
        if (this.v.form.$error) {
          return;
        }

        let chkEmail = {
          Email: this.form.email
        };
        this.isLoading = true;
        this.$axios
          .post(
            `${process.env.VUE_APP_API}/api/v1/user/ValidateEmail`,
            chkEmail
          )
          .then(data => {
            if (data.result == 1) {
              this.validationFormEdit();
            } else {
              this.successMessage = "Email นี้เคยสมัครแล้ว";
              this.$refs.modalError.show();
              this.isLoading = false;
            }
          });
      } else {
        this.validationFormEdit();
      }
    },
    async validationFormEdit() {
      this.v.form.$touch();

      this.v.dynamicField.$touch();
      if (this.v.dynamicField.$error || this.v.form.$error) {
        return;
      }
      let dynamic = [];
      for (const fields of this.dynamicField) {
        let target_id = 0;
        if (fields.field_choices.length > 0) {
          const findFieldId = fields.field_choices.find(
            choice => choice.name == fields.user_answer
          );
          if (findFieldId) {
            target_id = findFieldId.id;
          }
        }
        dynamic.push({
          id: fields.id,
          value: fields.user_answer,
          target_id: target_id
        });
      }
      let objTemp = [];

      for (const x of dynamic) {
        var findField = this.dynamicField.find(field => field.id == x.id);

        if (x.value && typeof x.value == "object") {
          if (x.value.length > 0) {
            for (const a of x.value) {
              const { id } = findField.field_choices.find(
                choice => choice.name == a
              );
              objTemp.push({
                id: x.id,
                value: a,
                target_id: id
              });
            }
          }
        } else {
          objTemp.push(x);
        }
      }

      if (!this.form.birthday_day) this.form.birthday_day = "01";
      if (!this.form.birthday_month) this.form.birthday_month = "01";
      if (!this.form.birthday_year) this.form.birthday_year = "1753";
      // this.form.custom_field = dynamic;
      this.isLoading = true;
      this.disabledBtn = true;
      delete this.form.birthday;
      let payload = {
        ...this.form,
        custom_field: objTemp.filter(el => el.value)
      };

      this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/user/EditUserProfile`, payload)
        .then(data => {
          if (data.result == 1) {
            if (this.customField.length > 0) {
              this.updateCustomField();
            } else {
              this.$router.push("/profile");
            }
          } else {
            this.disabledBtn = false;
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
            this.isLoading = false;
          }
        })
        .catch(err => {
          console.log(err.message);
          this.disabledBtn = false;

          this.successMessage = "เกิดข้อผิดพลาดไม่สามารถแก้ไขข้อมูลได้";

          this.$refs.modalError.show();
          this.isLoading = false;
        });
    },
    async updateCustomField() {
      let body = {
        user_guid: this.form.user_guid,
        admin_user_guid: this.form.user_guid,
        question_list: this.questionList
      };
      await this.$axios
        .put(
          `${process.env.VUE_APP_API}/api/v1/User/update_custom_answer`,
          body
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.$router.push("/profile");
          } else {
            this.disableBtn = false;
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
          }
        });
    },
    modalPolicyOpen() {
      if (this.$store.state.theme.is_custom_privacy_link == "1")
        return window.open(this.$store.state.theme.consent_url_link, "_blank");
      this.$EventBus.$emit("showModal", "policy");
    },
    modalTermsOpen() {
      if (this.$store.state.theme.is_term_and_con_url == "1")
        return window.open(this.$store.state.theme.term_and_con_url, "_blank");
      this.$EventBus.$emit("showModal", "term");
      // this.$router.push("/terms");
    }
  }
};
</script>

<style lang="scss" scoped>
.label-remark {
  color: red;
}
.ft-20 {
  font-size: var(--text-xl);
}
.div-space {
  margin: 90px 0px;
  background-color: #fff;
}
.text-error {
  color: #ff0000;
  font-size: var(--text-md);
}
::v-deep .vdatetime.date-picker-input.date-filter.error {
  border-bottom: 1px solid red !important;
}
.text-underline {
  text-decoration: underline;
}
.edit-telephone {
  position: absolute;
  bottom: 25%;
  transform: translateY(30%);
  right: 10px;
}
</style>
